import { CARD_TYPE_MAP, LeadCardType } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';

export const URL_SLUG_BY_CARD_TYPE: Record<LeadCardType, string | null> = {
  MATCHING_SUITES: 'suites',
  [CARD_TYPE_MAP.invitation]: 'invitations',
  [CARD_TYPE_MAP.std]: 'save-the-date',
  [CARD_TYPE_MAP.dstd]: 'digital/save-the-date',
  [CARD_TYPE_MAP.ctd]: 'change-the-date',
  [CARD_TYPE_MAP.thankyou]: 'thank-you-cards',
  [CARD_TYPE_MAP.menu]: 'menus',
  [CARD_TYPE_MAP.program]: 'programs',
  [CARD_TYPE_MAP.place]: 'place-cards',
  DETAILS: 'enclosures',
  [CARD_TYPE_MAP.enclosure]: 'enclosures',
  [CARD_TYPE_MAP.rsvp]: null, // secondary card type, no slug.
  ENVELOPE: null, // secondary card type, no slug.
  [CARD_TYPE_MAP.holiday]: 'holiday-cards',
  [CARD_TYPE_MAP.weddingShower]: 'bridal-shower',
  [CARD_TYPE_MAP.tableNumber]: 'table-numbers',
  [CARD_TYPE_MAP.paperAddOns]: 'paper-add-ons',
  [CARD_TYPE_MAP.bellyBand]: 'paper-add-ons',
  [CARD_TYPE_MAP.vellumJacket]: 'paper-add-ons',
  [CARD_TYPE_MAP.waxSeal]: 'paper-add-ons',
};
