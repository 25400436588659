import { CARD_TYPE_MAP, LeadCardType } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';

export const LABEL_BY_CARD_TYPE: Record<LeadCardType, string> = {
  MATCHING_SUITES: 'Matching suites',
  [CARD_TYPE_MAP.invitation]: 'Invitations',
  [CARD_TYPE_MAP.std]: 'Save the dates',
  [CARD_TYPE_MAP.dstd]: 'Digital save the dates',
  [CARD_TYPE_MAP.ctd]: 'Change the dates',
  [CARD_TYPE_MAP.thankyou]: 'Thank you cards',
  [CARD_TYPE_MAP.menu]: 'Menus',
  [CARD_TYPE_MAP.program]: 'Programs',
  [CARD_TYPE_MAP.place]: 'Place cards',
  DETAILS: 'Enclosures',
  [CARD_TYPE_MAP.enclosure]: 'Enclosures',
  [CARD_TYPE_MAP.rsvp]: 'RSVP',
  ENVELOPE: 'Envelope',
  [CARD_TYPE_MAP.holiday]: 'Holiday cards',
  [CARD_TYPE_MAP.weddingShower]: 'Wedding shower invitations',
  [CARD_TYPE_MAP.tableNumber]: 'Table numbers',
  [CARD_TYPE_MAP.paperAddOns]: 'Paper add-ons',
  [CARD_TYPE_MAP.bellyBand]: 'Paper add-ons',
  [CARD_TYPE_MAP.vellumJacket]: 'Paper add-ons',
  [CARD_TYPE_MAP.waxSeal]: 'Paper add-ons',
};
